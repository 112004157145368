import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {pricingTypes} from '../pricingTypes';
import {Space, Spin, message, Upload, List, Alert, Tag} from 'antd';
import JSONTree from 'react-json-tree';
import ApiFactory from '../ApiSpec';
import {FileUpload} from '@styled-icons/material/FileUpload';
import {NotInterested} from '@styled-icons/material/NotInterested';

import {errorHandler, jsonSchemaErrorHandler} from '../errors';
import {useOutlet} from 'reconnect.js';
import Tabs from '../Widgets/Tabs';
import Block from '../Widgets/Block';
import Hint from '../Widgets/Hint';
import Button from '../Widgets/Button';
const appConfig = require('../data.json');

const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

let pricingTypeOptions = pricingTypes
  .filter((x) => x !== 'custom')
  .map((x) => ({
    label: x,
    value: x,
  }));

export default function Uploader({}) {
  const [pricing_type, setPricingType] = useState(pricingTypeOptions[0].value);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [json_obj, setJsonObj] = useState(null);
  const [errors, setErrors] = useState([]);
  const [token] = useOutlet('jwtToken');
  const [actions] = useOutlet('actions');

  const api = useRef(
    new ApiFactory({
      apiHost: appConfig.endpoint.productSpecUploadUrl,
      bucketHost: appConfig.endpoint.productSpecUrl,
    }),
  ).current;
  const mounted = useRef(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await actions.getMicroServiceToken();
      mounted.current = true;
      setLoading(false);
    })();
  }, []);

  return (
    <Wrapper>
      {' '}
      <Spin spinning={loading}>
        <Tabs
          value={pricing_type}
          onChange={(value) => {
            setPricingType(value);
            setFile(null);
            setJsonObj(null);
            setErrors([]);
          }}
          options={pricingTypes
            .filter((p) => p !== 'custom')
            .map((p) => ({label: p, value: p}))}
          style={{marginBottom: 10}}
        />
        {/* {pricingTypes
            .filter((p) => p !== 'custom')
            .filter((p) => p !== 'common')
            .map((p) => (
              <Button
                key={p}
                type="text"
                style={{
                  border: 0,
                  borderBottom: `solid ${pricing_type === p ? 1 : 0}px ${
                    appConfig.colors.main
                  }`,
                }}
                onClick={() => {
                  setPricingType(p);
                }}>
                {p}
              </Button>
            ))} */}
        <Block style={{marginBottom: 20}}>
          <h2>匯出EXCEL</h2>
          <div className="divider" />
          <div className="content">
            <div className="row">
              <h4>規格類型</h4>
              <div style={{marginRight: 10}}>{pricing_type}</div>
              <Button
                href={`${appConfig.endpoint.productSpecUploadUrl}/csv/export?pricing_type=${pricing_type}&token=${token}`}
                target="_blank"
                onClick={async () => {
                  setLoading(true);
                  await delay(2000);
                  setLoading(false);
                }}>
                匯出
              </Button>
            </div>
          </div>
        </Block>

        <Block style={{marginBottom: 20}}>
          <h2>匯入EXCEL</h2>
          <div className="divider" />
          <div className="content">
            <div className="row">
              <h4>規格類型</h4>
              <div>{pricing_type}</div>
            </div>
            <div className="row">
              <h4>選擇檔案</h4>
              <Space>
                <Upload
                  beforeUpload={(file) => {
                    setFile(file);
                    return false;
                  }}
                  fileList={file ? [file] : []}>
                  <Button icon={<FileUpload size={16} color="#999" />}>
                    選擇檔案
                  </Button>
                </Upload>
                <Button
                  disabled={!file}
                  onClick={async () => {
                    try {
                      setLoading(true);
                      let json_obj = await api.importSpec({
                        file,
                        type: pricing_type,
                        token,
                      });
                      setJsonObj(json_obj);
                      message.success('上傳csv成功，已轉換成json');
                    } catch (ex) {
                      ex = ex.response;
                      if (ex.error === 'csv_validation_error') {
                        setErrors(jsonSchemaErrorHandler(ex));
                      }
                      errorHandler(ex);
                    }
                    setLoading(false);
                  }}>
                  上傳
                </Button>
                <Button
                  disabled={!json_obj}
                  type="primary"
                  onClick={async () => {
                    try {
                      setLoading(true);

                      await api.uploadSpec({
                        json: json_obj,
                        type: pricing_type,
                        token,
                      });
                      message.success('成功更新商品規格！');
                      setFile(null);
                      setJsonObj(null);
                      setErrors([]);
                    } catch (error) {
                      message.error('發生錯誤');
                    }
                    setLoading(false);
                  }}>
                  確認上傳
                </Button>
                <div style={{flex: 1}} />
                <Button
                  disabled={!json_obj}
                  onClick={() => {
                    setFile(null);
                    setJsonObj(null);
                    setErrors([]);
                  }}>
                  清空
                </Button>
              </Space>
            </div>

            <div className="row">
              <Hint type="info">確認上傳完成後，需等待網站建置幾分鐘。</Hint>
            </div>
            <div className="row">
              <Hint type="info">
                若無法加入購物車可能建置未完成，或規格有問題。
              </Hint>
            </div>
            <div className="row">
              <Hint type="info">商品消失時，可檢查分隔線是否完整。</Hint>
            </div>
            {pricing_type === 'common' && (
              <div className="row">
                <Hint type="warning">common 不適用</Hint>
              </div>
            )}
            {mounted.current && json_obj && (
              <JSONTree
                shouldExpandNode={false}
                hideRoot={true}
                data={json_obj}
              />
            )}
          </div>
        </Block>

        <Block>
          <h2>匯入JSON</h2>
          <div className="divider" />
          <div className="content">
            <div className="row">
              <h4>規格類型</h4>
              <div>{pricing_type}</div>
            </div>
            <div className="row">
              <h4>選擇檔案</h4>
              <Space>
                <Upload
                  beforeUpload={(file) => {
                    setFile(file);

                    let reader = new FileReader();
                    reader.onload = (e) => {
                      let obj = JSON.parse(e.target.result);
                      let _obj = obj[pricing_type];
                      setJsonObj(_obj);
                    };
                    reader.readAsText(file);
                    return false;
                  }}
                  fileList={file ? [file] : []}>
                  <Button icon={<FileUpload size={16} color="#999" />}>
                    選擇檔案
                  </Button>
                </Upload>
                <Button
                  disabled={!json_obj}
                  type="primary"
                  onClick={async () => {
                    try {
                      setLoading(true);

                      await api.uploadSpec({
                        json: json_obj,
                        type: pricing_type,
                        token,
                      });
                      message.success('成功更新商品規格！');
                      setFile(null);
                      setJsonObj(null);
                      setErrors([]);
                    } catch (error) {
                      message.error('發生錯誤');
                    }
                    setLoading(false);
                  }}>
                  確認上傳
                </Button>
                <div style={{flex: 1}} />
                <Button
                  disabled={!json_obj}
                  onClick={() => {
                    setFile(null);
                    setJsonObj(null);
                    setErrors([]);
                  }}>
                  清空
                </Button>
              </Space>
            </div>

            <div className="row">
              <Hint type="info">確認上傳完成後，需等待網站建置幾分鐘。</Hint>
            </div>
            <div className="row">
              <Hint type="info">
                若無法加入購物車可能建置未完成，或規格有問題。
              </Hint>
            </div>
            <div className="row">
              <Hint type="info">商品消失時，可檢查分隔線是否完整。</Hint>
            </div>
            {mounted.current && json_obj && (
              <JSONTree
                shouldExpandNode={false}
                hideRoot={true}
                data={json_obj}
              />
            )}
          </div>
        </Block>

        {errors.length > 0 ? (
          <Block>
            <div className="content" style={{color: appConfig.colors.error}}>
              {errors.map((e) => (
                <p>
                  <NotInterested
                    size={14}
                    color={appConfig.colors.error}
                    style={{marginRight: 5}}
                  />
                  {e.message}
                </p>
              ))}
            </div>
          </Block>
        ) : null}
      </Spin>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f5f5f5;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 30px;
  min-height: 1000px;

  & > .features {
    padding: 10px 20px;
  }

  & > .back-button {
    margin: 20px;
    color: #000;
    cursor: pointer;
  }

  & .filter-row {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    & > .label {
      margin-right: 10px;
      width: 120px;
    }
  }
`;

// const Block = styled.div`
//   width: 100%;
//   border-top: 9px solid #4d525d;
//   border-radius: 5px;
//   background-color: #ffffff;
//   margin: 10px;
//   margin-bottom: 30px;
//   flex: 1;

//   & > .title {
//     display: flex;
//     justify-content: space-between;
//     padding: 9px 23px;
//     border-bottom: 1px solid #eaeaea;
//   }

//   & > .border {
//     border: 1px solid #eee;
//     margin-top: 20px;
//   }
//   & > .content {
//     padding: 31px 23px;
//     overflow-y: scroll;

//     & > .border {
//       border-top: 1px solid #eee;
//       padding-top: 15px;
//     }
//     & > .can-click {
//       cursor: pointer;
//       padding: 5px;

//       :hover {
//         background-color: #f3f3f3;
//       }
//     }
//     & > .row {
//       display: flex;
//       align-items: center;
//       margin-bottom: 15px;
//       & > p {
//         font-size: 13px;
//         color: #19233b;
//         margin-bottom: 0;
//       }

//       & > h4 {
//         min-width: 100px;
//         margin-right: 20px;
//         margin-bottom: 0;
//         font-size: 13px;
//         font-weight: 600;
//         color: #19233b;
//       }

//       & > .cell {
//         width: 100px;
//         margin-right: 20px;
//         font-size: 13px;
//         color: #19233b;
//       }
//     }
//   }

//   & h2 {
//     font-size: 20px;
//     color: #19233b;
//   }
// `;
